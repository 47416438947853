import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import React, { useEffect } from "react";

export const Banner = () => {
  const logoRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);
  const bgRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tlAnimation = gsap.timeline({});

    tlAnimation
      .from(bgRef.current, { opacity: 0, duration: 1.2 })
      .from(logoRef.current, { opacity: 0, duration: 0.8, y: -100 })
      .from(titleRef.current, { opacity: 0, duration: 0.8, y: 100 }, "-=0.5");
  }, []);

  return (
    <div className="w-screen h-screen-4/5 relative flex justify-center items-center">
      <div className="absolute -z-1 w-full h-full" ref={bgRef}>
        <StaticQuery
          query={graphql`
            {
              file(
                relativePath: {
                  eq: "aerotec-digital/aerotec-digital-banner-1080.jpg"
                }
              ) {
                childImageSharp {
                  gatsbyImageData(quality: 100, breakpoints: 10)
                }
              }
            }
          `}
          render={(data) => (
            <GatsbyImage
              image={data.file.childImageSharp.gatsbyImageData}
              alt="Aerotec-digital-banner"
              className="w-full h-full"
            />
          )}
        />
      </div>
      <div className="flex justify-center items-center pt-20 flex-wrap gap-y-4 absolute z-10 lg:flex-row-reverse lg:px-10">
        <div className="basis-full lg:basis-1/2 flex justify-center" ref={logoRef}>
          <StaticImage
            src="../../../images/aerotec-digital/logo-simple.svg"
            alt="Aerotec-logo"
            className="w-1/2 block mx-auto sm:w-5/12 md:w-4/12 lg:w-8/12"
          />
        </div>
        <div
          className="basis-full text-center aerotec-digital-banner-title text-white lg:basis-2/3 xl:basis-1/2 xl:text-left"
          ref={titleRef}
        >
          <span className="text-xl font-semibold sm:text-2xl lg:text-3xl">
            Le damos la bienvenida,
          </span>
          <h1 className="text-5xl font-bold sm:text-6xl lg:text-7xl">
            Aerotec Digital
          </h1>
        </div>
      </div>
    </div>
  );
};
