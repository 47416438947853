import React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { TButtonElement } from "../types";

type RippleButtonHtmlProps = Omit<
  TButtonElement,
  "color" | "onMouseDown" | "style" | "ref"
>;

interface RippleButtonProps extends RippleButtonHtmlProps {
  children: React.ReactNode;
  multiplier?: number;
  color?: string;
  opacity?: number;
}

type CSSStyles = React.CSSProperties;

export const RippleButton: FC<RippleButtonProps> = ({
  children,
  multiplier = 1,
  color = "#ffffff",
  opacity = 0.4,
  ...rest
}) => {
  const refButton = useRef<HTMLButtonElement>(null);
  const refRipple = useRef<HTMLSpanElement>(null);
  const rippleTimeOut = useRef<any>(null);

  const [rippleClassName, setRippleClassName] =
    useState<React.HTMLAttributes<HTMLSpanElement>["className"]>("");
  const [rippleStyle, setRippleStyle] = useState<CSSStyles>({});

  const onMouseDown: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setRippleClassName("");
    clearTimeout(rippleTimeOut.current);

    const x = e.clientX;
    const y = e.clientY;

    const buttonData = refButton.current!.getBoundingClientRect();
    const startButtonPosX = buttonData.x;
    const startButtonPosY = buttonData.y;
    // const endButtonPosX = buttonData.x + buttonData.width;
    // const endButtonPosY = buttonData.y + buttonData.height;
    const buttonSize = buttonData.width * multiplier;

    const rippleSize = buttonSize;
    const ripplePosX = x - buttonSize / 2 - startButtonPosX;
    const ripplePosY = y - buttonSize / 2 - startButtonPosY;

    const newRippleStyle: CSSStyles = {
      top: `${ripplePosY}px`,
      left: `${ripplePosX}px`,
      width: `${rippleSize}px`,
      height: `${rippleSize}px`,
    };

    setRippleStyle(newRippleStyle);
    setRippleClassName("ripple-start ripple-active");

    rippleTimeOut.current = setTimeout(() => {
      setRippleClassName("");
    }, 500);
  };

  useEffect(() => {
    return () => clearTimeout(rippleTimeOut.current);
  }, []);

  return (
    <button
      ref={refButton}
      style={{ position: "relative", overflow: "hidden" }}
      onMouseDown={onMouseDown}
      {...rest}
    >
      <span
        ref={refRipple}
        className={`ripple ${rippleClassName}`}
        style={{ ...rippleStyle, backgroundColor: color, opacity }}
      ></span>
      {children}
    </button>
  );
};
