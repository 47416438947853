import React, { forwardRef, useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import { Card } from "./Card";
import { Paquete } from "./types";
import gsap from "gsap";

type AnimatedElement = Element & { timeLine: gsap.core.Timeline };

interface CardProps {
  refs: React.MutableRefObject<
    {
      alias: string;
      ref: React.RefObject<HTMLDivElement>;
    }[]
  >;
}

export const Cards = forwardRef(
  (props: CardProps, ref: React.LegacyRef<HTMLDivElement>) => {
    useEffect(() => {
      const checkDevice = (index: number) => {
        const isOdd = index % 2 === 0;
        const isMobile = window.innerWidth < 1024;

        const animationName = isMobile ? "translateX" : "translateY";
        const animationValue = isOdd ? "-50%" : "50%";

        return {
          animationName,
          animationValue,
        };
      };

      const options: IntersectionObserverInit = {
        threshold: 0.3,
      };

      const callback: IntersectionObserverCallback = (ents, obs) => {
        ents.forEach((ent) => {
          const target = ent.target as AnimatedElement;

          if (ent.isIntersecting) {
            target.timeLine.play();
          } else {
            target.timeLine.reverse();
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);

      const animatedElements = document.querySelectorAll(".card-container");

      animatedElements.forEach((el, index) => {
        // const isOdd = index % 2 === 0;
        const { animationName, animationValue } = checkDevice(index);
        const card = el.querySelector(".card") as HTMLDivElement;

        const action = gsap
          .timeline({ paused: true, defaults: { duration: 1 } })
          .from(card, {
            [animationName]: animationValue,
            opacity: 0,
          });

        (el as AnimatedElement).timeLine = action;
      });

      Array.prototype.forEach.call(animatedElements, (el, index) =>
        observer.observe(el)
      );

      return () => {
        Array.prototype.forEach.call(animatedElements, (el, index) =>
          observer.unobserve(el)
        );
      };
    }, []);

    return (
      <StaticQuery
        query={graphql`
          query {
            data: dataJson {
              paquetes {
                alias
                nombre_imagen
                paquete
                titulo
                ruta_imagen {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      height: 800
                      breakpoints: 10
                      width: 800
                    )
                  }
                }
              }
            }
          }
        `}
        render={(data: { data: { paquetes: Paquete[] } }) => {
          return (
            <div
              className="cards flex gap-12 md:gap-6 flex-wrap my-6 justify-center w-11/12 mx-auto md:w-full pb-4 overflow-hidden"
              ref={ref}
            >
              {data.data.paquetes.map((item, key) => (
                <Card
                  key={key}
                  pack={item.paquete}
                  title={item.titulo}
                  image={item.ruta_imagen.childImageSharp.gatsbyImageData}
                  elementRef={props.refs.current[key].ref}
                />
              ))}
            </div>
          );
        }}
      />
    );
  }
);
