import React, { forwardRef, useEffect, useRef } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { FaTimesCircle, FaWhatsapp } from "react-icons/fa";
import Link from "../../lib/customLink";
import { RippleButton } from "../../lib/rippleButton";
import gsap from "gsap";

interface CardProps {
  image: IGatsbyImageData;
  title: string;
  pack: string[];
  elementRef: React.RefObject<HTMLDivElement>;
}

export const Card = forwardRef(
  ({ image, title, pack, elementRef }: CardProps, ref) => {
    const backCardRef = useRef<HTMLDivElement>(null);

    const handleOpenCard = () => {
      gsap.to(backCardRef.current, {
        translateY: 0,
      });
    };

    const handleCloseCard = () => {
      gsap.to(backCardRef.current, {
        translateY: "100%",
      });
    };

    const handleScroll: React.MouseEventHandler<HTMLButtonElement> = () => {
      const target = elementRef.current;

      target?.scrollIntoView({ behavior: "smooth" });
    };

    return (
      <div className="card-container basis-full xl:basis-3/12 md:basis-5/12">
        <div className="card bg-slate-100 rounded-2xl drop-shadow-md relative overflow-hidden w-full">
          <div className="front-card relative">
            <RippleButton
              onClick={handleOpenCard}
              className="rounded-2xl drop-shadow-md"
              title="Hazme click"
              aria-label="Ver información del paquete"
            >
              <div className="image-container rounded-2xl">
                <GatsbyImage
                  image={image}
                  alt={title.toLowerCase().replace("", "-")}
                  className="rounded-2xl"
                />
              </div>
            </RippleButton>
            <div className="content px-6 py-4">
              <h3 className="text-center font-semibold text-black text-2xl mb-6">
                {title}
              </h3>
              <RippleButton
                className="block bg-aerotec-dark-10 px-6 py-2 rounded-lg mx-auto text-gray-100 transition-colors duration-100 hover:bg-aerotec-dark-20"
                opacity={0.15}
                onClick={handleScroll}
              >
                Ver más acerca de esto
              </RippleButton>
            </div>
          </div>
          <div
            className="back-card absolute bg-white z-10 top-0 left-0 w-full h-full rounded-2xl px-6 py-5 translate-y-full"
            ref={backCardRef}
          >
            <button
              aria-label="Cerrar descripción"
              className="absolute right-4 top-4 text-slate-700 transition-colors duration-150 hover:text-slate-800"
              title="Cerrar"
              onClick={handleCloseCard}
            >
              <FaTimesCircle className="w-8 h-8 " />
            </button>
            <div className="text-center mb-4">
              <span className="font-bold text-2xl text-aerotec-dark-15">
                {title}
              </span>
            </div>

            <p className="text-slate-900 text-lg">
              El paquete de {title.toLowerCase()} te ofrece:
            </p>
            <br />
            <ul className="pl-6 text-lg text-slate-800">
              {pack.map((item, key) => (
                <li key={key} className="list-disc">
                  {item}
                </li>
              ))}
            </ul>

            <br />

            <p className="text-slate-900 text-lg">
              Todo en entrega digital y correo.
            </p>

            <br />

            <div className="absolute bottom-10 flex justify-center left-0 right-0">
              <Link
                to=""
                className="flex items-center justify-center text-gray-100 bg-green-700 px-10 py-3 w-10/12 rounded-lg transition-all duration-200 hover:bg-green-800 hover:-translate-y-1"
              >
                <FaWhatsapp className="mr-2 w-6 h-6" />
                <span className="">Solicitar más información</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
