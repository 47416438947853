import React, { forwardRef } from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Articulo } from "./types";

interface ArticulosProps {
  refs: React.MutableRefObject<
    {
      alias: string;
      ref: React.RefObject<HTMLDivElement>;
    }[]
  >;
}

export const Articulos = forwardRef(
  (props: ArticulosProps, ref: React.LegacyRef<HTMLDivElement>) => {
    return (
      // @ts-ignore
      <StaticQuery
        query={graphql`
          {
            data: dataJson {
              articulos {
                titulo
                descripcion
                subtitulo
                subdescripcion
                lista
                nombre_imagen
                ruta_imagen {
                  childImageSharp {
                    gatsbyImageData(quality: 90, breakpoints: 10)
                  }
                }
              }
            }
          }
        `}
        render={(data: { data: { articulos: Articulo[] } }) => {
          return (
            <>
              <div className="articulos relative" ref={ref}>
                {data.data.articulos.map((articulo, index) => (
                  <div
                    key={index}
                    className="articulos my-4"
                    ref={props.refs.current[index].ref}
                  >
                    <h2 className="text-center font-bold text-4xl mb-6 text-aerotec-dark md:text-6xl">
                      {articulo.titulo}
                    </h2>
                    <p className="text-xl text-slate-900 leading-8 md:text-2xl md:leading-10 xl:text-3xl xl:leading-relaxed">
                      {articulo.descripcion}
                    </p>
                    <br />
                    <br />
                    <h3 className="text-center mb-4 text-3xl font-semibold md:text-5xl md:mb-6">
                      {articulo.subtitulo}
                    </h3>
                    <br />
                    <div
                      className={`flex justify-center flex-wrap w-full md:w-10/12 md:mx-auto xl:w-8/12 ${
                        articulo.ruta_imagen.length > 1 && "gap-y-2"
                      }`}
                    >
                      {articulo["ruta_imagen"].map((imagen, index) => (
                        <div
                          key={index}
                          className={`${
                            articulo.ruta_imagen.length > 1 &&
                            "flex-shrink flex-grow basis-1/2"
                          }`}
                        >
                          <GatsbyImage
                            image={imagen.childImageSharp.gatsbyImageData}
                            alt={articulo.titulo
                              .toLowerCase()
                              .replace(" ", "-")}
                            className="rounded-lg"
                          />
                        </div>
                      ))}
                    </div>
                    <br />
                    <br />
                    <p className="text-xl text-slate-900 leading-8 md:text-2xl md:leading-10 xl:text-3xl xl:leading-relaxed">
                      {articulo.subdescripcion}
                    </p>
                    <br />
                    {articulo.lista.length > 0 && (
                      <ul className="text-xl text-slate-900 list-disc md:text-2xl md:leading-10 xl:text-3xl xl:leading-relaxed">
                        {articulo.lista.map((texto, index) => (
                          <li
                            key={index}
                            className="ml-6 mb-3 md:ml-8 xl:ml-12"
                          >
                            {texto}
                          </li>
                        ))}
                      </ul>
                    )}
                    <br />
                    <div className="hr"></div>
                    <br />
                  </div>
                ))}
              </div>
              <br />
            </>
          );
        }}
      />
    );
  }
);
