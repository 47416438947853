import React, { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import { Banner } from "../components/themed/aerotecDigitalPage/Banner";
import { Cards } from "../components/themed/aerotecDigitalPage/Cards";
import { Articulos } from "../components/themed/aerotecDigitalPage/Article";
import { graphql, PageProps } from "gatsby";
import { FaArrowUp } from "react-icons/fa";
import GrupoAerotecLogos from "../components/themed/grupoAerotecLogos";

type DataPageProps = {
  data: {
    paquetes: { alias: string }[];
  };
};

const AerotecDigitalPage = (props: PageProps<DataPageProps>) => {
  const linkRefs = useRef(
    props.data.data.paquetes.map((paquete) => ({
      alias: paquete.alias,
      ref: React.createRef<HTMLDivElement>(),
    }))
  );

  const [showUpButton, setShowUpButton] = useState(false);

  const articlesRef = useRef<HTMLDivElement>(null);
  const packagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options: IntersectionObserverInit = {
      threshold: 0.1,
    };

    const callback: IntersectionObserverCallback = (ents, obs) => {
      ents.forEach((ent) => {
        if (ent.isIntersecting) {
          setShowUpButton(true);
        } else {
          setShowUpButton(false);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (articlesRef.current) {
      observer.observe(articlesRef.current);
      console.log(articlesRef.current);
    }
  }, []);

  const handleButtonClick = () => {
    packagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Layout
      navbar={{
        Logo: (
          <StaticImage
            src="../images/aerotec-digital/logo-aerotec-digital.png"
            alt="Logo-aerotec-digital"
          />
        ),
      }}
      pageTitle="AEROTEC DIGITAL"
    >
      <Banner />
      <div className="divider"></div>
      <section className="min-h-screen">
        <div className="container">
          <div className="text-center mt-4 mb-6">
            <h2 className="font-bold text-6xl mb-3 text-aerotec-dark-15">
              Paquetes
            </h2>
            <p className="font-semibold text-xl text-slate-900">
              Conoce los paquetes que te ofrecemos
            </p>
          </div>
        </div>
        <Cards refs={linkRefs} ref={packagesRef} />
      </section>
      <div className="divider"></div>
      <section className="min-h-screen">
        <div className="container">
          <Articulos refs={linkRefs} ref={articlesRef} />
        </div>
      </section>
      <div className="divider"></div>
      {showUpButton && (
        <button
          className="aerotec-gradient-bg p-4 fixed bottom-5 right-5 rounded-full text-white"
          aria-label="Regresar a los paquetes"
          title="Regresar a los paquetes"
          onClick={handleButtonClick}
        >
          <FaArrowUp className="w-6 h-6" />
        </button>
      )}
      <section>
        <GrupoAerotecLogos />
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    data: dataJson {
      paquetes {
        alias
      }
    }
  }
`;

export default AerotecDigitalPage;
